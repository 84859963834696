import React, { useState } from "react"
import Hero from "../components/hero"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"
import Button from "../components/button"
import { MDXRenderer } from "gatsby-plugin-mdx"

export const query = graphql`
query Bio {
    mdx(fileAbsolutePath: {regex: "/bio.md/"}) {
        frontmatter {
            heading
            hero {
                childImageSharp {
                    fluid(maxWidth: 2500) {
                        src
                    }
                }
            }
            profile1 {
                childImageSharp {
                    fluid(maxWidth: 750) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            profile2 {
                childImageSharp {
                    fluid(maxWidth: 750) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            profile3 {
                childImageSharp {
                    fluid(maxWidth: 750) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            collabs_en
            collabs_fi
            collabs_slo
            masters_en
            masters_fi
            masters_slo
            collabs
            masterclasses
        }
        body
    }
    bioen: mdx(fileAbsolutePath: {regex: "/bio_en.md/"}) {
        frontmatter {
            p1
            p2
            p3
            p4
            p5
            p6
        }
        body
    }
    biofi: mdx(fileAbsolutePath: {regex: "/bio_fi.md/"}) {
        frontmatter {
            p1
            p2
            p3
            p4
            p5
            p6
        }
        body
    }
    bioslo: mdx(fileAbsolutePath: {regex: "/bio_slo.md/"}) {
        frontmatter {
            p1
            p2
            p3
            p4
            p5
            p6
        }
        body
    }
}
`
const Biography = ({ data }) => {
    const [language, setLanguage] = useState("en")
    const heroImg = data.mdx.frontmatter.hero.childImageSharp.fluid.src
    const portrait = data.mdx.frontmatter.profile1.childImageSharp.fluid
    const portrait2 = data.mdx.frontmatter.profile2.childImageSharp.fluid
    const portrait3 = data.mdx.frontmatter.profile3.childImageSharp.fluid
    return (
        <>
            <SEO title="Bio"
                keywords={[`conductor`, `choral conductor`, `choir conductor`, `choir`, `chorus`]} />
            <Hero imageSrc={heroImg} textColor={t => t.theme.colors.background} minHeight="50vh">
                <h1>Biography</h1>
            </Hero>
            <div style={{
                display: 'flex',
                justifyContent: 'space-around',
                marginBottom: '1rem',
            }}>
                <Btn marginTop="0" fontSize="10px" padding="10px 10px" onClick={() => setLanguage("en")}>in English</Btn>
                <Btn marginTop="0" fontSize="10px" padding="10px 10px" onClick={() => setLanguage("fi")}>suomeksi</Btn>
                <Btn marginTop="0" fontSize="10px" padding="10px 10px" onClick={() => setLanguage("slo")}>v slovenščini</Btn>
            </div>
            {language === "en" &&
                <Page id="en">
                    <p>
                        {data.bioen.frontmatter.p1}
                    </p>
                    <PageLayout>
                        <ProfilePic fluid={portrait} />
                        <ProfileText>
                            <p>
                                {data.bioen.frontmatter.p2}
                            </p>
                        </ProfileText>
                    </PageLayout>
                    <p>
                        {data.bioen.frontmatter.p3}
                    </p>
                    <PageLayout reverse={true}>
                        <ProfilePic fluid={portrait2} reverse={true} />
                        <ProfileText>
                            <p>
                                {data.bioen.frontmatter.p4}
                            </p>
                        </ProfileText>
                    </PageLayout>
                    <p>
                        {data.bioen.frontmatter.p5}
                    </p>
                    <PageLayout>
                        <ProfilePic fluid={portrait3} />
                        <ProfileText>
                            <p>
                                {data.bioen.frontmatter.p6}
                            </p>
                        </ProfileText>
                    </PageLayout>


                    <MDXRenderer>
                        {data.bioen.body}
                    </MDXRenderer>
                    <hr />
                    <h3>{data.mdx.frontmatter.collabs_en}</h3>
                    <p>{data.mdx.frontmatter.collabs}</p>
                    <h3>{data.mdx.frontmatter.masters_en}</h3>
                    <p>{data.mdx.frontmatter.masterclasses}</p>
                </Page>
            }

            {language === "fi" &&
                <Page id="fi">
                    <p>
                        {data.biofi.frontmatter.p1}
                    </p>
                    <PageLayout>
                        <ProfilePic fluid={portrait} />
                        <ProfileText>
                            <p>
                                {data.biofi.frontmatter.p2}
                            </p>
                        </ProfileText>
                    </PageLayout>
                    <p>
                        {data.biofi.frontmatter.p3}
                    </p>

                    <PageLayout reverse={true}>
                        <ProfilePic fluid={portrait2} reverse={true} />
                        <ProfileText>
                            <p>
                                {data.biofi.frontmatter.p4}
                            </p>
                        </ProfileText>
                    </PageLayout>

                    <p>
                        {data.biofi.frontmatter.p5}
                    </p>
                    <PageLayout>
                        <ProfilePic fluid={portrait3} />
                        <ProfileText>
                            <p>
                                {data.biofi.frontmatter.p6}
                            </p>
                        </ProfileText>
                    </PageLayout>

                    <MDXRenderer>
                        {data.biofi.body}
                    </MDXRenderer>
                    <h3>{data.mdx.frontmatter.collabs_fi}</h3>
                    <p>{data.mdx.frontmatter.collabs}</p>
                    <h3>{data.mdx.frontmatter.masters_fi}</h3>
                    <p>{data.mdx.frontmatter.masterclasses}</p>
                </Page>
            }
            {language === "slo" &&
                <Page id="slo">
                    <p>
                        {data.bioslo.frontmatter.p1}
                    </p>
                    <PageLayout>
                        <ProfilePic fluid={portrait} />
                        <ProfileText>
                            <p>
                                {data.bioslo.frontmatter.p2}
                            </p>
                        </ProfileText>
                    </PageLayout>
                    <p>
                        {data.bioslo.frontmatter.p3}
                    </p>

                    <PageLayout reverse={true}>
                        <ProfilePic fluid={portrait2} reverse={true} />
                        <ProfileText>
                            <p>
                                {data.bioslo.frontmatter.p4}
                            </p>
                        </ProfileText>
                    </PageLayout>
                    <p>
                        {data.bioslo.frontmatter.p5}
                    </p>
                    <PageLayout>
                        <ProfilePic fluid={portrait3} />
                        <ProfileText>
                            <p>
                                {data.bioslo.frontmatter.p6}
                            </p>
                        </ProfileText>
                    </PageLayout>

                    <MDXRenderer>
                        {data.bioslo.body}
                    </MDXRenderer>
                    <h3>{data.mdx.frontmatter.collabs_slo}</h3>
                    <p>{data.mdx.frontmatter.collabs}</p>
                    <h3>{data.mdx.frontmatter.masters_slo}</h3>
                    <p>{data.mdx.frontmatter.masterclasses}</p>
                </Page>
            }
            <div>
                <hr />

            </div>
            <div>
                <MDXRenderer>
                    {data.mdx.body}
                </MDXRenderer>
            </div>

        </>
    )
}

export default Biography

const ProfilePic = styled(BackgroundImage)`
height: 250px;
margin: 1rem 0;
flex: 0 0 50%;
@media (min-width: 786px) {
    height: auto;
    min-height: 400px;
    margin: ${props => props.reverse ? "0.5rem 0rem 1.75rem 1rem" : "0.5rem 1rem 1.75rem 0"};
    justify-content: flex-start;
    background-size: auto 100%;
}
`
const ProfileText = styled.div`


`
const PageLayout = styled.div`
width: 100%;
display: flex;
flex-direction: column;

@media (min-width: 786px) {
    flex-direction: ${props => (props.reverse ? "row-reverse" : "row")};
}
`
const Page = styled.div`

`
const Btn = styled(Button)`
flex: 0 1 100px;
`